<template>
  <div>
    <location />
    <div class="page">
      <tab />
      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-2"
          role="tabpanel"
          aria-labelledby="pills-2-tab"
        >
          <div class="wrap-1200">
            <b-form @submit="onSubmit" class="filter-group mb-4">
              <div class="row row-cols-1 row-cols-md-auto g-2 ms-md-auto">
                <div class="col date-search">
                  <input type="date" v-model="searchData.startdate" />
                  <input type="date" v-model="searchData.enddate" />
                </div>

                <div class="col">
                  <div class="row g-2">
                    <div class="col">
                      <select v-model="searchData.pagesize">
                        >
                        <option value="10">
                          {{ $t("filter.common.sort_by_10") }}
                        </option>
                        <option value="30">
                          {{ $t("filter.common.sort_by_30") }}
                        </option>
                        <option value="50">
                          {{ $t("filter.common.sort_by_50") }}
                        </option>
                        <option value="100">
                          {{ $t("filter.common.sort_by_100") }}
                        </option>
                      </select>
                    </div>
                    <div class="col col-md-auto">
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          name="searchname"
                          v-model="searchData.searchname"
                          :placeholder="
                            $t('alert.common.field_enter', {
                              field: $t('text.common.payment_item'),
                            })
                          "
                        />
                        <button
                          class="btn btn-primary btn-md icon-search"
                          type="submit"
                        ></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-form>

            <div class="row flex-wrap justify-content-end gx-3 gy-1 mb-3 fs-14">
              <div class="col-auto">
                <span class="fw-500 text-black">
                  {{ $t("title.common.entry_ticket") }}</span
                >
                :
                {{ playerpaymentinfo.entry_ticket_cnt }}
              </div>
              <div class="col-auto">
                <span class="fw-500 text-black">
                  {{ $t("title.common.used_ticket") }}</span
                >
                :
                {{ playerpaymentinfo.used_ticket_cnt }}
              </div>
            </div>

            <table class="table board">
              <thead>
                <tr>
                  <th class="d-lg-table-cell">
                    {{ $t("title.common.number") }}
                  </th>
                  <th class="d-none d-lg-table-cell">
                    {{ $t("title.membership.payment_no") }}
                  </th>
                  <th class="col-auto d-lg-table-cell">
                    {{ $t("text.common.payment_item") }}
                  </th>
                  <th class="col-auto d-none d-lg-table-cell">
                    {{ $t("title.common.location") }}
                  </th>
                  <th class="col-auto d-none d-lg-table-cell">
                    {{ $t("text.common.entry_fee") }}
                  </th>
                  <th class="col-auto d-none d-lg-table-cell">
                    {{ $t("title.membership.payment_date") }}
                  </th>
                  <th class="col-auto d-none d-lg-table-cell">
                    {{ $t("title.membership.used_date") }}
                  </th>
                  <th class="th-arrow">&nbsp;</th>
                </tr>
              </thead>
              <tbody v-if="total_cnt > 0">
                <template v-for="(item, index) in items">
                  <tr :key="index">
                    <td class="d-lg-table-cell">
                      {{ item.no }}
                    </td>
                    <td class="d-none d-lg-table-cell">
                      {{ item.tor_key }}
                    </td>
                    <td class="d-lg-table-cell">
                      {{ item.title }}
                    </td>
                    <td class="d-lg-table-cell d-none shop-name">
                      {{ item.shopname }}
                    </td>
                    <td class="d-lg-table-cell d-none text-price">
                      {{ item.tor_total_money | toFixed | price_unit }}
                    </td>
                    <td class="d-none d-lg-table-cell text-date">
                      {{ item.timezone_regdate.timezone_private | dateformat }}
                    </td>
                    <td class="d-none d-lg-table-cell text-date">
                      <template v-if="item.tod_status == '1'">
                        {{
                          item.timezone_used_regdate.timezone_private
                            | dateformat
                        }}
                      </template>
                      <template v-else-if="item.tod_status == '2'">
                        {{
                          item.timezone_refund_regdate.timezone_private
                            | dateformat
                        }}
                        <p class="text-capitalize text-price">
                          ({{ $t("text.common.refund") }})
                        </p>
                      </template>
                    </td>

                    <td class="td-arrow" @click="toggle_tr(index)">
                      <img
                        src="/img/arrow_none_collapsed.png"
                        :class="{ 'btn-toggle-arrow': view_hide === index }"
                      />
                    </td>
                  </tr>
                  <tr
                    class="d-lg-none"
                    :class="{ show: view_hide === index }"
                    :key="index + 'r'"
                    v-if="view_hide === index"
                  >
                    <td colspan="7">
                      <div class="toggle-content">
                        <div class="row">
                          <div class="col-4">
                            {{ $t("title.membership.payment_no") }}
                          </div>
                          <div class="col-8">{{ item.tor_key }}</div>
                        </div>

                        <div class="row">
                          <div class="col-4">
                            {{ $t("title.common.location") }}
                          </div>
                          <div class="col-8 shop-name">
                            {{ item.shopname }}
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-4">
                            {{ $t("text.common.entry_fee") }}
                          </div>
                          <div class="col-8 text-price">
                            {{ item.tor_total_money | toFixed | price_unit }}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            {{ $t("title.membership.payment_date") }}
                          </div>
                          <div class="col-8 text-date">
                            {{
                              item.timezone_regdate.timezone_private
                                | dateformat
                            }}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            {{ $t("title.membership.used_date") }}
                          </div>
                          <div class="col-8 text-date">
                            <template v-if="item.tod_status == '1'">
                              {{
                                item.timezone_used_regdate.timezone_private
                                  | dateformat
                              }}
                            </template>
                            <template v-else-if="item.tod_status == '2'">
                              {{
                                item.timezone_refund_regdate.timezone_private
                                  | dateformat
                              }}
                              <p class="fs-13 text-capitalize text-price">
                                ({{ $t("text.common.refund") }})
                              </p>
                            </template>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="7" class="text-center">
                    {{ $t("text.common.no_data") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <b-pagination-nav
            :link-gen="linkGen"
            :number-of-pages="page_total"
            v-model="searchData.pageno"
            use-router
            aria-label="Page navigation"
            v-if="page_total > 1"
            first-class="prev"
            prev-class="prev"
            next-class="next"
            last-class="next"
            hide-ellipsis
            limit="10"
          >
            <template #first-text>
              <i class="material-icons">keyboard_double_arrow_left</i>
            </template>
            <template #prev-text>
              <i class="material-icons">navigate_before</i>
            </template>
            <template #next-text>
              <i class="material-icons">navigate_next</i>
            </template>
            <template #last-text>
              <i class="material-icons">keyboard_double_arrow_right</i>
            </template>
            <template #page="{ page, active }">
              <a v-if="active">{{ page }}</a>
              <a v-else>{{ page }}</a>
            </template>
          </b-pagination-nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import tab from "@/components/membership/Tab";
import ax from "@/api/membership";
import "@/filter/common";
import { myMixin } from "@/mixin/myMixin";

export default {
  name: "MyPayment",
  components: {
    location,
    tab,
  },
  data() {
    return {
      searchData: {
        pageno: this.$route.query.pageno || 1,
        searchname:
          this.$route.query.searchname ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchname) ||
          "",
        status:
          this.$route.query.status ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].status) ||
          "",
        startdate:
          this.$route.query.startdate ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].startdate) ||
          this.$moment(new Date().setMonth(new Date().getMonth() - 1)).format(
            "YYYY-MM-DD"
          ),
        enddate:
          this.$route.query.enddate ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].enddate) ||
          this.$moment(new Date()).format("YYYY-MM-DD"),
        pagesize:
          this.$route.query.pagesize ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].pagesize) ||
          10,
      },
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      rows: 10,
      items: [],
      view_hide: null,
      view_hide3: null,
      playerpaymentlist: [],
      playerpaymentinfo: {},
    };
  },
  methods: {
    // get_playerpaymentlist() {
    //   this.$store.commit("setSearchData", {
    //     [this.$route.name]: this.searchData,
    //   });
    //   ax.get_playerpaymentlist(this.searchData, (flag, data) => {
    //     this.total_cnt = data.listcnt;
    //     this.page_total = Math.ceil(data.listcnt / this.searchData.pagesize);
    //     this.items = data.list;
    //     this.playerpaymentinfo = data.playerinfo;
    //     if (!flag) alert(data.message);
    //   });
    // },
    get_paymentlist() {
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });
      ax.get_paymentlist(this.searchData, (flag, data) => {
        this.total_cnt = data.listcnt;
        this.page_total = Math.ceil(data.listcnt / this.searchData.pagesize);
        this.items = data.list;
        this.playerpaymentinfo = data.playerinfo;
        if (!flag) alert(data.message);
      });
    },
    onSubmit(event) {
      event.preventDefault();
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
  },
  created() {
    this.get_paymentlist();
  },
  watch: {
    "searchData.searchname": function () {
      this.searchData.pageno = 1;
      this.get_paymentlist();
    },
    "searchData.status": function () {
      this.searchData.pageno = 1;
      this.get_paymentlist();
    },
    "searchData.startdate": function () {
      this.searchData.pageno = 1;
      this.get_paymentlist();
    },
    "searchData.enddate": function () {
      this.searchData.pageno = 1;
      this.get_paymentlist();
    },
    "searchData.type": function () {
      this.searchData.pageno = 1;
      this.get_paymentlist();
    },
    "searchData.pagesize": function () {
      this.searchData.pageno = 1;
      this.get_paymentlist();
    },
    "searchData.pageno": function () {
      this.get_paymentlist();
    },
  },
  mixins: [myMixin],
};
</script>
