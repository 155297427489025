<template>
  <div>
    <location />
    <div class="page wrap-1200 stats">
      <div class="d-flex justify-content-center align-items-center mb-3">
        <button
          class="btn p-0"
          :class="{
            'text-opacity-25': searchData.searchdate < 2025,
          }"
          @click="prev()"
        >
          <i class="material-icons fs-1">keyboard_arrow_left</i>
        </button>
        <h5 class="mx-2 text-center">
          {{ searchData.searchdate }} {{ $t("text.common.season") }}
        </h5>
        <button
          class="btn p-0"
          :class="{
            'text-opacity-25':
              searchData.searchdate ==
              $dayjs(new Date().setYear(new Date().getFullYear())).format(
                'YYYY'
              ),
          }"
          @click="next()"
        >
          <i class="material-icons fs-1">keyboard_arrow_right</i>
        </button>
      </div>

      <b-form
        @submit="onSubmit"
        class="filter-group row row-cols-2 row-cols-sm-auto g-2 justify-content-end"
      >
        <div class="col">
          <select aria-label="" v-model="searchData.pagesize">
            <option value="10" selected>
              {{ $t("filter.common.sort_by_10") }}
            </option>
            <option value="50">{{ $t("filter.common.sort_by_50") }}</option>
            <option value="100">
              {{ $t("filter.common.sort_by_100") }}
            </option>
          </select>
        </div>
        <div class="col">
          <select aria-label="Select one" v-model="searchData.searchtype">
            <option value="nickname">
              {{ $t("filter.common.nickname") }}
            </option>
          </select>
        </div>
        <div class="col-12">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              name="searchname"
              v-model="searchData.searchname"
            />
            <button
              class="btn btn-primary btn-md icon-search"
              type="submit"
            ></button>
          </div>
        </div>
      </b-form>

      <b-table-simple responsive table-class="table-xs table-sorting">
        <colgroup>
          <col span="2" />
          <col
            :class="{ 'col-sorted': searchData.rank_field == 'avg_score' }"
          />
          <col :class="{ 'col-sorted': searchData.rank_field == 'greens' }" />
          <col
            :class="{ 'col-sorted': searchData.rank_field == 'avg_drive' }"
          />
          <col :class="{ 'col-sorted': searchData.rank_field == 'avg_putt' }" />
          <col :class="{ 'col-sorted': searchData.rank_field == 'parsave' }" />
          <col :class="{ 'col-sorted': searchData.rank_field == 'fairway' }" />
        </colgroup>
        <b-thead>
          <b-tr>
            <b-th sticky-column class="w-1">
              {{ $t("text.common.ranking_abbr") }}
            </b-th>

            <b-th sticky-column>
              {{ $t("title.common.player") }}
            </b-th>

            <b-th @click="_sort('avg_score')">
              <a>
                {{ $t("title.common.avg_score") }}
                <template v-if="searchData.rank_field != 'avg_score'">
                  <i class="sorted-none"></i>
                </template>
                <template v-else>
                  <i v-if="searchData.sort == 'DESC'" class="sorted-desc"></i>
                  <i v-else class="sorted-asc"></i>
                </template>
              </a>
            </b-th>

            <b-th @click="_sort('greens')">
              <a>
                {{ $t("title.common.gir") }}
                <template v-if="searchData.rank_field != 'greens'">
                  <i class="sorted-none"></i>
                </template>
                <template v-else>
                  <i v-if="searchData.sort == 'DESC'" class="sorted-desc"></i>
                  <i v-else class="sorted-asc"></i>
                </template>
              </a>
            </b-th>

            <b-th @click="_sort('avg_drive')">
              <a>
                {{ $t("title.common.avg_distance") }}
                <template v-if="searchData.rank_field != 'avg_drive'">
                  <i class="sorted-none"></i>
                </template>
                <template v-else>
                  <i v-if="searchData.sort == 'DESC'" class="sorted-desc"></i>
                  <i v-else class="sorted-asc"></i>
                </template>
              </a>
            </b-th>

            <b-th @click="_sort('avg_putt')">
              <a>
                {{ $t("title.common.avg_putts_distance") }}
                <template v-if="searchData.rank_field != 'avg_putt'">
                  <i class="sorted-none"></i>
                </template>
                <template v-else>
                  <i v-if="searchData.sort == 'DESC'" class="sorted-desc"></i>
                  <i v-else class="sorted-asc"></i>
                </template>
              </a>
            </b-th>

            <b-th @click="_sort('parsave')">
              <a>
                {{ $t("title.common.par_save_rate") }}
                <template v-if="searchData.rank_field != 'parsave'">
                  <i class="sorted-none"></i>
                </template>
                <template v-else>
                  <i v-if="searchData.sort == 'DESC'" class="sorted-desc"></i>
                  <i v-else class="sorted-asc"></i>
                </template>
              </a>
            </b-th>

            <b-th @click="_sort('fairway')">
              <a>
                {{ $t("title.common.fareway_hit") }}
                <template v-if="searchData.rank_field != 'fairway'">
                  <i class="sorted-none"></i>
                </template>
                <template v-else>
                  <i v-if="searchData.sort == 'DESC'" class="sorted-desc"></i>
                  <i v-else class="sorted-asc"></i>
                </template>
              </a>
            </b-th>
          </b-tr>
        </b-thead>

        <b-tbody v-if="items.length > 0">
          <template v-for="(item, index) in items">
            <b-tr :key="index">
              <b-td sticky-column>
                <strong class="text-black">{{
                  searchData.sort == "ASC"
                    ? index + 1 + (searchData.pageno - 1) * searchData.pagesize
                    : items_.length -
                      (index + (searchData.pageno - 1) * searchData.pagesize)
                }}</strong>
              </b-td>
              <b-td sticky-column class="d-flex align-items-center text-start">
                <MemberInfo
                  :playerno="item.player_pk"
                  :member_img="item.member_img"
                  :badge_img="item.badge_img"
                  :class_bind="'avatar-md'"
                />
                <div>
                  <MemberInfo
                    :nickname="item.nickname"
                    :playerno="item.player_pk"
                    :avatar_img="false"
                    class="fw-500"
                  />
                  <p class="fs-12 fw-normal shop-name">
                    {{ item.shopname || "-" }}
                  </p>
                </div>
              </b-td>
              <b-td>
                {{ item.avg_score | toFixed }}
              </b-td>
              <b-td>
                {{ item.greens | toFixed | suffix("%") }}
              </b-td>
              <b-td>
                {{ item.avg_drive | toFixed | suffix("m") }}
              </b-td>
              <b-td>
                {{ item.avg_putt | toFixed | suffix("m") }}
              </b-td>
              <b-td>
                {{ item.parsave | toFixed | suffix("%") }}
              </b-td>
              <b-td>
                {{ item.fairway | toFixed | suffix("%") }}
              </b-td>
            </b-tr>
          </template>
        </b-tbody>
        <b-tbody v-else>
          <b-tr>
            <b-td colspan="8" class="text-center">
              {{ $t("text.common.no_data") }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <b-pagination-nav
        :link-gen="linkGen"
        :number-of-pages="Math.ceil(items_.length / searchData.pagesize)"
        v-model="searchData.pageno"
        use-router
        aria-label="Page navigation"
        v-if="items_.length / searchData.pagesize > 1"
        first-class="prev"
        prev-class="prev"
        next-class="next"
        last-class="next"
        hide-ellipsis
        limit="10"
      >
        <template #first-text
          ><i class="material-icons">keyboard_double_arrow_left</i></template
        >
        <template #prev-text
          ><i class="material-icons">navigate_before</i></template
        >
        <template #next-text
          ><i class="material-icons">navigate_next</i></template
        >
        <template #last-text
          ><i class="material-icons">keyboard_double_arrow_right</i></template
        >
        <template #page="{ page, active }">
          <div v-if="active">{{ page }}</div>
          <div v-else>{{ page }}</div>
        </template>
      </b-pagination-nav>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/halloffame";
import { myMixin } from "@/mixin/myMixin";
import "@/filter/common";

export default {
  name: "Stats",
  components: {
    location,
  },
  data() {
    return {
      searchData: {
        rank_field:
          this.$route.query.rank_field ||
          (this.$store.state.searchData[this.$route.matched[0].name] &&
            this.$store.state.searchData[this.$route.matched[0].name]
              .rank_field) ||
          "avg_score",
        sort:
          this.$route.query.sort ||
          (this.$store.state.searchData[this.$route.matched[0].name] &&
            this.$store.state.searchData[this.$route.matched[0].name].sort) ||
          "ASC",

        searchdate:
          this.$route.params.searchdate ||
          (this.$store.state.searchData[this.$route.matched[0].name] &&
            this.$store.state.searchData[this.$route.matched[0].name]
              .searchdate) ||
          this.$dayjs(new Date().setYear(new Date().getFullYear())).format(
            "YYYY"
          ),
        searchtype:
          this.$route.query.searchtype ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchtype) ||
          "nickname",
        searchname:
          this.$route.query.searchname ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchname) ||
          "",
        pageno: this.$route.query.pageno || 1,

        pagesize:
          this.$route.query.pagesize ||
          (this.$store.state.searchData[this.$route.matched[0].name] &&
            this.$store.state.searchData[this.$route.matched[0].name]
              .pagesize) ||
          "10",
      },
      page_total: 1,
      total_cnt: 0,

      items: [],
      items_: [],
      shotstats: [],
      view_hide: null,
    };
  },
  methods: {
    prev() {
      if (this.searchData.searchdate < 2025) return;
      this.searchData.searchdate--;
    },
    next() {
      if (
        this.searchData.searchdate ==
        this.$dayjs(new Date().setYear(new Date().getFullYear())).format("YYYY")
      )
        return;
      this.searchData.searchdate++;
    },
    get_list() {
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });
      ax.get_statslist(this.searchData, (flag, data) => {
        // this.items = data.list;

        // this.total_cnt = data.listcnt;
        // this.page_total = Math.ceil(data.listcnt / this.searchData.pagesize);
        this.shotstats = data.list;

        this.setInit();
        if (!flag) alert(data.message);
      });
    },

    setInit() {
      if (this.searchData.searchname)
        this.items_ = this.shotstats.filter((v) =>
          v[this.searchData.searchtype]
            .toLowerCase()
            .includes(this.searchData.searchname.toLowerCase())
        );
      else this.items_ = this.shotstats;
      // console.log("this.items_", this.items_);
      if (
        (this.searchData.sort == "ASC" &&
          this.searchData.rank_field == "avg_score") ||
        (this.searchData.sort == "DESC" &&
          this.searchData.rank_field != "avg_score")
      ) {
        this.items_.sort((a, b) => {
          if (
            parseFloat(a[this.searchData.rank_field]) >
            parseFloat(b[this.searchData.rank_field])
          ) {
            return 1;
          }
          if (
            parseFloat(a[this.searchData.rank_field]) <
            parseFloat(b[this.searchData.rank_field])
          ) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
      } else {
        this.items_.sort((a, b) => {
          if (
            parseFloat(a[this.searchData.rank_field]) <
            parseFloat(b[this.searchData.rank_field])
          ) {
            return 1;
          }
          if (
            parseFloat(a[this.searchData.rank_field]) >
            parseFloat(b[this.searchData.rank_field])
          ) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
      }
      this.items = this.items_.slice(
        (this.searchData.pageno - 1) * this.searchData.pagesize,
        this.searchData.pageno * this.searchData.pagesize
      );
    },
    onSubmit(event) {
      event.preventDefault();
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
    _sort(rank_field) {
      if (this.searchData.rank_field == rank_field)
        this.searchData.sort = this.searchData.sort === "DESC" ? "ASC" : "DESC";
      else this.searchData.rank_field = rank_field;
    },
  },
  created() {
    this.get_list();
  },

  watch: {
    "searchData.searchdate": function () {
      this.searchData.pageno = 1;
      this.get_list();
    },
    "searchData.searchname": function () {
      this.searchData.pageno = 1;
      this.setInit();
    },
    "searchData.sort": function () {
      this.searchData.pageno = 1;
      this.setInit();
    },
    "searchData.pageno": function () {
      this.setInit();
    },
    "searchData.pagesize": function () {
      this.searchData.pageno = 1;
      this.setInit();
    },
    "searchData.searchtype": function () {
      this.searchData.pageno = 1;
      this.setInit();
    },
    "searchData.rank_field": function () {
      this.searchData.pageno = 1;
      this.searchData.sort = "ASC";
      this.setInit();
    },
  },
  mixins: [myMixin],
};
</script>
