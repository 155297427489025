import Vue from "vue";
import i18n from "@/i18n";
import dayjs from "dayjs";

Vue.filter("comma", (value) => {
  //if (value) return String(value).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') // ios 오류
  //if (value) return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',') // 소수점도 , 찍힘
  if (value) {
    const parts = value.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  } else return 0;
});

Vue.filter("toFixed", (value, text) => {
  if (value) {
    return Number(value).toFixed(2);
  } else return text || 0;
});

Vue.filter("transToValue", (value, text) => {
  if (value == "0") {
    return text;
  } else return value || 0;
});

Vue.filter("GMTtuUTCTIME", (value) => {
  if (value) {
    var date = new Date(value);
    var now_utc = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );

    return new Date(now_utc)
      .toISOString()
      .replace("T", " ")
      .replace(/\..*/, "");
  } else {
    return "";
  }
});

Vue.filter("GMTtuUTC", (value) => {
  if (value) {
    var date = new Date(value);
    var now_utc = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );

    return new Date(now_utc).toISOString().split("T")[0];
  } else {
    return "";
  }
});

Vue.filter("category", (category, type) => {
  console.log(type);
  if (category == 1) {
    return i18n.t("filter.support.manual");
  } else if (category == 2) {
    if (type === "list") return i18n.t("filter.support.promotion");
    else return i18n.t("filter.support.promotional_material");
  } else if (category == 3) {
    if (type === "list") return i18n.t("filter.support.printed");
    else return i18n.t("filter.support.printed_material");
  } else if (category == 4) {
    return i18n.t("filter.support.other");
  } else {
    return "None";
  }
});

Vue.filter("get_img", (value, default_img) => {
  if (value) {
    return process.env.VUE_APP_S3_BUCKET_URL + "/" + value;
  }
  if (default_img) {
    return default_img;
  }

  return "/img/no-img.png";
});

Vue.filter("get_download", (value) => {
  if (value) {
    return process.env.VUE_APP_S3_BUCKET_URL + "/" + value;
  }
});

Vue.filter("get_club_type", (value) => {
  switch (value) {
    case "1":
      return i18n.t("text.common.driver");

    case "2":
      return i18n.t("text.common.wood");

    case "3":
      return i18n.t("text.common.utility");

    case "4":
      return i18n.t("text.common.iron");

    case "5":
      return i18n.t("text.common.wedge");

    case "6":
      return i18n.t("text.common.putter");
  }
});

Vue.filter("get_shaft_material", (value) => {
  switch (value) {
    case "1":
      return i18n.t("text.common.graphite");

    case "2":
      return i18n.t("text.common.steel");

    case "3":
      return i18n.t("text.common.carbon");
  }
});

Vue.filter("get_balance", (value) => {
  switch (value) {
    case "1":
      return "R";

    case "2":
      return "SR";

    case "3":
      return "S";

    case "4":
      return "L";
  }
});

Vue.filter("get_interestclub", (value) => {
  switch (value) {
    case "1":
      return i18n.t("text.common.sense_of_shot");

    case "2":
      return i18n.t("text.common.sense_of_direction");

    case "3":
      return i18n.t("text.common.sense_of_distance");
  }
});

Vue.filter("get_club_code", (value) => {
  switch (value) {
    case "1":
      return "DR";

    case "2":
      return "W2";

    case "3":
      return "I3";

    case "4":
      return "U4";

    case "5":
      return "PW";

    case "6":
      return "AW";

    default:
      return "ETC";
  }
});

Vue.filter("get_lesson_title", (value) => {
  switch (value) {
    case "lesson1":
      return "Address";

    case "lesson2":
      return "Back Swing";

    case "lesson3":
      return "Top Swing";

    case "lesson4":
      return "Down Swing";

    case "lesson5":
      return "Impact";

    case "lesson6":
      return "Follow Through";

    case "lesson7":
      return "Finish";

    case "lessontotal":
      return "Total";
  }
});

Vue.filter("teeboxType", (teebox) => {
  if (teebox === "1") {
    // return i18n.t("text.common.pro") + "(" + i18n.t("text.common.blue") + ")";
    return i18n.t("text.common.blue");
  } else if (teebox === "2") {
    return (
      // i18n.t("text.common.regular") + "(" + i18n.t("text.common.white") + ")"
      i18n.t("text.common.white")
    );
  } else if (teebox === "3") {
    // return i18n.t("text.common.lady") + "(" + i18n.t("text.common.red") + ")";
    return i18n.t("text.common.red");
  } else if (teebox === "4") {
    return (
      // i18n.t("text.common.senior") + "(" + i18n.t("text.common.yellow") + ")"
      i18n.t("text.common.yellow")
    );
  } else {
    return i18n.t("text.common.none");
  }
});

Vue.filter("dateformat", (value, type) => {
  if (value) {
    if (type) return dayjs(value).format(type);
    else return dayjs(value).format("YYYY.MM.DD HH:mm");
  } else {
    return "-";
  }
});

Vue.filter("tour_class", (value) => {
  switch (value) {
    case "1":
      return i18n.t("text.admin.regular");

    case "2":
      return i18n.t("text.admin.major");
  }
});

Vue.filter("tour_rank_type", (value) => {
  switch (value) {
    case "0":
      return (
        i18n.t("text.common.low_gross") + " / " + i18n.t("text.common.low_net")
      );
    case "1":
      return i18n.t("text.common.low_gross");

    case "2":
      return i18n.t("text.common.low_net");
  }
});

Vue.filter("suffix", (value, text = "") => {
  if (value == "0") {
    return "-";
  } else return value + text;
});

Vue.filter("price_unit", (value, text = "") => {
  if (process.env.VUE_APP_PRICE_UNIT === "AUD") {
    return "$ " + value + " AUD";
  }
  return "$ " + value;
});
