import axios from "@/api/axios";
import store from "@/store";
import _ from "lodash";
import qs from "qs";

// 경기리스트
const get_memberplaylist = async (param, result) => {
  let finalData = {
    code: "",
    message: "",
    pageno: 1,
    pagesize: 10,
    playlistcnt: 0,
    playlist: [],
  };
  const token = sessionStorage.getItem("token") || null;

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/player/roundlist",
    //url: 'http://localhost/test.php',
    headers: { Authorization: "Bearer " + token },
    params: {
      startdate: param.startdate,
      enddate: param.enddate,
      pageno: param.pageno,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message, response.data.code);
      }
    });
  } catch (err) {
    console.error("get_memberplaylist", err);
    result(false, err);
  }
};

// 스코어카드
const get_memberscorecard = async (param, result) => {
  let finalData = {
    code: "",
    message: "",
    scorecardinfo: [],
    scorecardstats: [],
    scorecardscore: [],
    scorecardtotal: {},
  };
  const token = sessionStorage.getItem("token") || null;

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/player/scorecard/" + param.gameno,
    //url: 'http://localhost/test.php',
    headers: { Authorization: "Bearer " + token },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_memberscorecard", err);
    result(false, err);
  }
};

// 대회리스트
const get_membertournamentlist = async (param, result) => {
  let finalData = {
    code: "",
    message: "",
    pageno: 1,
    pagesize: 10,
    tournamentlistcnt: 0,
    tournamentlist: [],
  };
  const token = sessionStorage.getItem("token") || null;

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/player/tounamentlist",
    //url: 'http://localhost/test.php',
    headers: { Authorization: "Bearer " + token },
    params: {
      status: param.status,
      pageno: param.pageno,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message, response.data.code);
      }
    });
  } catch (err) {
    console.error("get_membertournamentlist", err);
    result(false, err);
  }
};

// 이벤트리스트
const get_membereventwinninglist = async (param, result) => {
  let finalData = {
    code: "",
    message: "",
    pageno: 1,
    pagesize: 10,
    eventwinninglistcnt: 0,
    eventwvinninglist: [],
  };
  const token = sessionStorage.getItem("token") || null;

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/player/minigamewinning",
    //url: 'http://localhost/test.php',
    headers: { Authorization: "Bearer " + token },
    params: {
      startdate: param.startdate,
      enddate: param.enddate,
      pageno: param.pageno,
      type: param.type,
      status: param.status,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message, response.data.code);
      }
    });
  } catch (err) {
    console.error("get_membereventwinninglist", err);
    result(false, err);
  }
};

// 스윙리스트
const get_memberswinglist = async (param, result) => {
  let finalData = {
    code: "",
    message: "",
    pageno: 1,
    pagesize: 10,
    swinglistcnt: 0,
    swinglist: [],
  };
  const token = sessionStorage.getItem("token") || null;

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/player/swinglist",
    //url: 'http://localhost/test.php',
    headers: { Authorization: "Bearer " + token },
    params: {
      startdate: param.startdate,
      enddate: param.enddate,
      pageno: param.pageno,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message, response.data.code);
      }
    });
  } catch (err) {
    console.error("get_memberswinglist", err);
    result(false, err);
  }
};

// 스윙 공개여부
const set_memberswingopen = async (swingno, flag, result) => {
  const token = sessionStorage.getItem("token") || null;

  const options = {
    method: "PUT",
    url:
      process.env.VUE_APP_API_URL +
      "/postact/swingopen/" +
      swingno +
      "/" +
      flag,
    //url: 'http://localhost/test.php',
    headers: { Authorization: "Bearer " + token },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data.message);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("set_memberswingopen", err);
    result(false, err);
  }
};

// 스윙영상 삭제
const memberswingdrop = async (swingno, result) => {
  const token = sessionStorage.getItem("token") || null;

  const options = {
    method: "PUT",
    url: process.env.VUE_APP_API_URL + "/postact/swingdrop/" + swingno,
    //url: 'http://localhost/test.php',
    headers: { Authorization: "Bearer " + token },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data.message);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("memberswingdrop", err);
    result(false, err);
  }
};

// 회원정보보기
const get_memberinfo = async (result) => {
  let finalData = {
    code: "",
    message: "",
    memberinfo: [],
  };
  const token = sessionStorage.getItem("token") || null;

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/player/view",
    //url: 'http://localhost/test.php',
    headers: { Authorization: "Bearer " + token },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData, response.data.code);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message, response.data.code);
      }
    });
  } catch (err) {
    console.error("get_memberinfo", err);
    result(false, err, response.data.code);
  }
};

// 정보수정 중복체크
const availability_check2 = async (type, name, result) => {
  const token = sessionStorage.getItem("token") || null;
  let url = "";
  let data = {};
  if (type === 1) {
    url = "ajax_player_id_check";
    data = { player_id: name };
  }
  if (type === 2) {
    url = "ajax_nickname_check";
    data = { nickname: name };
  }
  if (type === 3) {
    url = "ajax_email_check";
    data = { email: name };
  }

  const options = {
    method: "POST",
    url: process.env.VUE_APP_API_URL + "/Player_modify/" + url,
    //url: 'http://localhost/test.php',
    headers: { Authorization: "Bearer " + token },
    data: qs.stringify(data),
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data.message, response.data.code);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message, response.data.code);
      }
    });
  } catch (err) {
    console.error("availability_check2", err);
    result(false, err);
  }
};

// 정보수정
const member_edit = async (form, result) => {
  const token = sessionStorage.getItem("token") || null;
  const data = new FormData();

  for (let key in form) {
    data.append(key, form[key]);
  }

  const options = {
    method: "POST",
    url: process.env.VUE_APP_API_URL + "/player_modify",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },

    data: data,
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data.code, response.data.message);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.code, response.data.message);
      }
    });
  } catch (err) {
    console.error("member_edit", err);
    result(false, err);
  }
};

// 단골매장리스트
const get_memberprefershop = async (param, result) => {
  let finalData = {
    code: "",
    message: "",
    pageno: 1,
    pagesize: 10,
    shoplist: 0,
    shoplistcnt: [],
  };
  const token = sessionStorage.getItem("token") || null;

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/player/prefershop",
    //url: 'http://localhost/test.php',
    headers: { Authorization: "Bearer " + token },
    params: {
      searchtype: param.searchtype,
      searchname: param.searchname,
      pageno: param.pageno,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message, response.data.code);
      }
    });
  } catch (err) {
    console.error("get_memberprefershop", err);
    result(false, err);
  }
};

// 매장선택
const memberprefershopchoose = async (shopno, chk, result) => {
  const token = sessionStorage.getItem("token") || null;

  const options = {
    method: "PUT",
    url:
      process.env.VUE_APP_API_URL + "/postact/shopchoose/" + shopno + "/" + chk,
    headers: { Authorization: "Bearer " + token },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data.message);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("memberprefershopchoose", err);
    result(false, err);
  }
};

// 매장상세보기
const get_membershopinfo = async (shopno, result) => {
  const token = sessionStorage.getItem("token") || null;

  let finalData = {
    code: "",
    message: "",
    shopinfo: [],
  };

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/player/shopinfo/" + shopno,
    headers: { Authorization: "Bearer " + token },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_membershopinfo", err);
    result(false, err);
  }
};

// 비밀번호재확인
const memberpwd_recheck = async (password2, result) => {
  const token = sessionStorage.getItem("token") || null;

  const options = {
    method: "POST",
    url: process.env.VUE_APP_API_URL + "/player_modify/checkpasswd",
    headers: { Authorization: "Bearer " + token },
    data: qs.stringify({
      password: password2,
    }),
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data.code, response.data.message);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.code, response.data.message);
      }
    });
  } catch (err) {
    console.error("memberpwd_recheck", err);
    result(false, 400, err);
  }
};

// 회원탈퇴
const member_drop = async (result) => {
  const token = sessionStorage.getItem("token") || null;

  const options = {
    method: "DELETE",
    url: process.env.VUE_APP_API_URL + "/player_modify/playerdrop",
    headers: { Authorization: "Bearer " + token },
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data.message);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("member_drop", err);
    result(false, err);
  }
};

// 토너먼트 조건 불러오기
const get_membertournamentcondition = async (tournamentno, result) => {
  let finalData = {
    code: "",
    message: "",
    mytournamentsview: [],
  };

  const token = sessionStorage.getItem("token") || null;

  const options = {
    method: "GET",
    url:
      process.env.VUE_APP_API_URL +
      "/player/tournamentcondition/" +
      tournamentno,
    headers: { Authorization: "Bearer " + token },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_membertournamentcondition", err);
    result(false, err);
  }
};

// 토너먼트참가리스트
const get_membertournamentplaylist = async (param, result) => {
  let finalData = {
    code: "",
    message: "",
    pageno: 1,
    pagesize: 10,
    tournamentname: "",
    tournamentplaylistcnt: 0,
    tournamentplaylist: [],
  };
  const token = sessionStorage.getItem("token") || null;

  const options = {
    method: "GET",
    url:
      process.env.VUE_APP_API_URL +
      "/player/tournamentplaylist/" +
      param.tournamentno,
    headers: { Authorization: "Bearer " + token },
    params: {
      pageno: param.pageno,
    },
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message, response.data.code);
      }
    });
  } catch (err) {
    console.error("get_membertournamentplaylist", err);
    result(false, err);
  }
};

// 토너먼트 랭킹 가져오기
const get_membertournamentranklist = async (param, result) => {
  let finalData = {
    code: "",
    message: "",
    subpageno: 1,
    pagesize: 10,
    tournamentname: "",
    tournamentranklistcnt: 0,
    tournamentranklist: [],
  };
  const token = sessionStorage.getItem("token") || null;

  const options = {
    method: "GET",
    url:
      process.env.VUE_APP_API_URL +
      "/player/tournamentranking/" +
      param.tournamentno,
    headers: { Authorization: "Bearer " + token },
    params: {
      scoretype: param.scoretype,
      gender: param.gender,
      subpageno: param.subpageno,
      pagesize: param.pagesize,
      tour_rank_type: param.tour_rank_type,
    },
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_membertournamentranklist", err);
    result(false, err);
  }
};

// 토너먼트 랭킹 가져오기
const get_eventwinningranklist = async (param, result) => {
  let finalData = {
    code: 200,
    message: "Ok",
    eventwinningrankcnt: 0,
    eventwinningranklist: [],
    eventinfo: {},
  };
  const token = sessionStorage.getItem("token") || null;

  const options = {
    method: "GET",
    url:
      process.env.VUE_APP_API_URL +
      "/player/eventwinningranklist/" +
      param.eventno,
    headers: { Authorization: "Bearer " + token },
    params: {
      gender: param.gender,
      pageno: param.pageno,
    },
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_eventwinningranklist", err);
    result(false, err);
  }
};

// 토너먼트 개인 랭킹 가져오기
const get_tournamentrankinginfo = async (param, result) => {
  let finalData = {
    code: "",
    message: "",
    tournamentrankinfo: [],
  };
  const token = sessionStorage.getItem("token") || null;

  const options = {
    method: "GET",
    url:
      process.env.VUE_APP_API_URL +
      "/player/tournamentrankinginfo/" +
      param.tournamentno,
    headers: { Authorization: "Bearer " + token },
    params: {
      scoretype: param.scoretype,
      gender: param.gender,
      pageno: param.pageno,
    },
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_tournamentrankinginfo", err);
    result(false, err);
  }
};

// 스윙보기
const get_memberswingview = async (swingno, result) => {
  let finalData = {
    code: "",
    message: "",
    swingview: [],
  };
  const token = sessionStorage.getItem("token") || null;

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/player/swingview/" + swingno,
    headers: { Authorization: "Bearer " + token },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_memberswingview", err);
    result(false, err);
  }
};

// 회원정보요약
const get_membersummary = async (result) => {
  let finalData = {
    code: "",
    message: "",
    summaryinfo: null,
    recentplayinfo: null,
    swinginfo: null,
  };
  const token = sessionStorage.getItem("token") || null;

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/player/summary",
    headers: { Authorization: "Bearer " + token },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData, response.data.code);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data, response.data.code);
      }
    });
  } catch (err) {
    console.error("get_membersummary", err);
    result(false, err, 400);
  }
};

// 스윙댓글리스트
const get_memberswingreplylist = async (swingno, pageno, result) => {
  let finalData = {
    code: "",
    message: "",
    pageno: 1,
    pagesize: 10,
    swingreplylistcnt: 0,
    swingreplylist: [],
  };
  const token = sessionStorage.getItem("token") || null;

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/postact/swingreplylist/" + swingno,
    headers: { Authorization: "Bearer " + token },
    params: {
      pageno: pageno,
    },
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_memberswingreplylist", err);
    result(false, err);
  }
};

// 스윙댓글등록 등록
const set_memberswingreplyanswerwrite = async (swingno, text, result) => {
  const token = sessionStorage.getItem("token") || null;

  const options = {
    method: "POST",
    url: process.env.VUE_APP_API_URL + "/postact/swingreplywrite/" + swingno,
    headers: { Authorization: "Bearer " + token },
    data: qs.stringify({
      text: text,
    }),
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data.message);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("set_memberswingreplyanswerwrite", err);
    result(false, err);
  }
};

// 스윙댓글등록 삭제
const set_memberswingreplyanswerdrop = async (swingno, replyno, result) => {
  const token = sessionStorage.getItem("token") || null;

  const options = {
    method: "DELETE",
    url:
      process.env.VUE_APP_API_URL +
      "/postact/swingreplydrop/" +
      swingno +
      "/" +
      replyno,
    headers: { Authorization: "Bearer " + token },
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data.message);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("set_memberswingreplyanswerdrop", err);
    result(false, err);
  }
};

const getPointList = async (result) => {
  let finalData = {
    pointList: [],
  };
  const token = sessionStorage.getItem("token") || null;

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/player/pointlist",
    //url: 'http://localhost/test.php',
    headers: { Authorization: "Bearer " + token },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData, response.data.code);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message, response.data.code);
      }
    });
  } catch (err) {
    console.error("set_memberswingopen", err);
    result(false, err, response.data.code);
  }
};

// 스윙title 등록
const set_swingtitlewrite = async (swingno, title, content, flag, result) => {
  const token = sessionStorage.getItem("token") || null;

  const options = {
    method: "POST",
    url: process.env.VUE_APP_API_URL + "/player/swingtitlewrite/" + swingno,
    headers: { Authorization: "Bearer " + token },
    data: qs.stringify({
      title: title,
      content: content,
      flag: flag,
    }),
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data.message);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("set_memberswingreplyanswerwrite", err);
    result(false, err);
  }
};

// 이벤트 당첨내역
const get_myeventwinninglist = async (param, result) => {
  const token = sessionStorage.getItem("token") || null;
  let finalData = {
    code: "",
    message: "",
    pageno: 1,
    pagesize: 10,
    eventwinninglistcnt: 0,
    eventwvinninglist: [],
  };

  const options = {
    method: "GET",
    url:
      process.env.VUE_APP_API_URL +
      "/player/myeventwinninglist/" +
      param.eventno,
    //url: 'http://localhost/test.php',
    headers: { Authorization: "Bearer " + token },
    params: {
      searchtype: param.searchtype,
      searchname: param.searchname,
      pageno: param.pageno,
      type: param.type,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_myeventwinninglist", err);
    result(false, err);
  }
};

// 통합관리이벤트정보보기
const get_eventview = async (eventno, result) => {
  let finalData = {
    code: 200,
    message: "Ok",
    eventinfo: {},
  };
  const token = sessionStorage.getItem("token") || null;
  const options = {
    method: "GET",
    url: "/event/eventview/" + eventno,
    headers: { Authorization: "Bearer " + token },
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_admineventview", err);
    result(false, err);
  }
};

// 타임존리스트(주)
const get_timezonelist = async (result) => {
  let finalData = {
    code: 200,
    message: "Ok",
    stateslistcnt: 0,
    stateslist: [],
  };

  const options = {
    method: "GET",
    url: "/common/timezonelist",
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_timezonelist", err);
    result(false, err);
  }
};

// 지역리스트(주)
const get_stateslist = async (countryno, result) => {
  let finalData = {
    code: 200,
    message: "Ok",
    stateslistcnt: 0,
    stateslist: [],
  };

  const options = {
    method: "GET",
    url: "/common/stateslist/" + countryno,
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_stateslist", err);
    result(false, err);
  }
};

// 나라리스트
const get_countrylist = async (result) => {
  let finalData = {
    code: 200,
    message: "Ok",
    countrylist: [],
  };

  const options = {
    method: "GET",
    url: "/common/countrylist",
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_countrylist", err);
    result(false, err);
  }
};

// 타임존리스트(주)
const get_prefershoplist = async (result) => {
  let finalData = {
    code: 200,
    message: "Ok",
    shoplistcnt: 0,
    shoplist: [],
  };

  const options = {
    method: "GET",
    url: "/common/prefershoplist",
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_prefershoplist", err);
    result(false, err);
  }
};

// 포인트리스트
const get_playerpointlist = async (param, result) => {
  let finalData = {
    code: "",
    message: "",
    pageno: 1,
    pagesize: 10,
    pointlistcnt: 0,
    pointlist: [],
  };
  const token = sessionStorage.getItem("token") || null;

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/player/playerpointlist",
    //url: 'http://localhost/test.php',
    headers: { Authorization: "Bearer " + token },
    params: {
      startdate: param.startdate,
      enddate: param.enddate,
      pageno: param.pageno,
      pagesize: param.pagesize,
      pointtype: param.pointtype,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_playerpointlist", err);
    result(false, err);
  }
};

const set_badge = async (badge_pk, result) => {
  const token = sessionStorage.getItem("token") || null;

  let finalData = {
    code: 200,
    message: "Ok",
  };

  const options = {
    method: "PUT",
    url: "/postact/set_badge/" + badge_pk,
    headers: { Authorization: "Bearer " + token },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    result(false, err);
  }
};

const remove_badge = async (result) => {
  const token = sessionStorage.getItem("token") || null;

  let finalData = {
    code: 200,
    message: "Ok",
  };

  const options = {
    method: "PUT",
    url: "/postact/remove_badge/",
    headers: { Authorization: "Bearer " + token },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    result(false, err);
  }
};

// 토너먼트 결제 리스트
const get_playerpaymentlist = async (param, result) => {
  const token = sessionStorage.getItem("token") || null;

  let finalData = {
    code: 200,
    message: "Ok",
    listcnt: 0,
    list: [],
  };

  const options = {
    method: "GET",
    url: "/player/playerpaymentlist/" + param.tour_pk,
    headers: { Authorization: "Bearer " + token },
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_playerpaymentlist", err);
    result(false, err);
  }
};

const get_paymentlist = async (param, result) => {
  const token = sessionStorage.getItem("token") || null;

  let finalData = {
    code: 200,
    message: "Ok",
    listcnt: 0,
    list: [],
  };

  const options = {
    method: "GET",
    url: "/player/paymentlist",
    headers: { Authorization: "Bearer " + token },
    params: {
      startdate: param.startdate,
      enddate: param.enddate,
      pageno: param.pageno,
      searchtype: param.searchtype,
      searchname: param.searchname,
      pagesize: param.pagesize,
    },
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_paymentlist", err);
    result(false, err);
  }
};

const get_stats = async (param, result) => {
  const token = sessionStorage.getItem("token") || null;

  let finalData = {
    code: 200,
    message: "Ok",
    listcnt: 0,
    list: [],
  };

  const options = {
    method: "GET",
    url: "/player/stats",
    headers: { Authorization: "Bearer " + token },
    params: {
      field: param.field,
      chart_recent: param.chart_recent,
    },
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_paymentlist", err);
    result(false, err);
  }
};

export default {
  get_memberplaylist,
  get_memberscorecard,
  get_membertournamentlist,
  get_membereventwinninglist,
  get_memberswinglist,
  set_memberswingopen,
  memberswingdrop,
  get_memberinfo,
  availability_check2,
  member_edit,
  get_memberprefershop,
  memberprefershopchoose,
  get_membershopinfo,
  memberpwd_recheck,
  member_drop,
  get_membertournamentcondition,
  get_membertournamentplaylist,
  get_membertournamentranklist,
  get_tournamentrankinginfo,
  get_eventwinningranklist,
  get_memberswingview,
  get_membersummary,
  get_memberswingreplylist,
  set_memberswingreplyanswerwrite,
  set_memberswingreplyanswerdrop,
  getPointList,
  set_swingtitlewrite,
  get_myeventwinninglist,
  get_eventview,
  get_stateslist,
  get_countrylist,
  get_timezonelist,
  get_prefershoplist,
  get_playerpointlist,
  set_badge,
  remove_badge,
  get_playerpaymentlist,
  get_paymentlist,
  get_stats,
};
