<template>
  <div>
    <location />
    <div class="page wrap-1200 stats">
      <div class="d-flex justify-content-center align-items-center mb-3">
        <button
          class="btn p-0"
          :class="{
            'text-opacity-25': searchData.searchdate < 2025,
          }"
          @click="prev()"
        >
          <i class="material-icons fs-1">keyboard_arrow_left</i>
        </button>
        <h5 class="mx-2 text-center">
          {{ searchData.searchdate }} {{ $t("text.common.season") }}
        </h5>
        <button
          class="btn p-0"
          :class="{
            'text-opacity-25':
              searchData.searchdate ==
              $dayjs(new Date().setYear(new Date().getFullYear())).format(
                'YYYY'
              ),
          }"
          @click="next()"
        >
          <i class="material-icons fs-1">keyboard_arrow_right</i>
        </button>
      </div>

      <div class="row row-cols-1 row-cols-md-2 gx-lg-5 gy-4">
        <div class="col">
          <h6 class="subtitle">{{ $t("title.common.avg_score") }}</h6>
          <table class="table table-xs">
            <thead>
              <tr>
                <th class="w-1">
                  {{ $t("text.common.ranking_abbr") }}
                </th>
                <th>
                  {{ $t("title.common.player") }}
                </th>
                <th class="w-1">
                  {{ $t("title.common.score") }}
                </th>
              </tr>
            </thead>
            <tbody v-if="summary.top_avg_score_list.length > 0">
              <template v-for="(item, index) in summary.top_avg_score_list">
                <tr :key="index">
                  <td>
                    <strong class="text-black">{{ item.ranking }}</strong>
                  </td>
                  <td class="d-flex align-items-center text-start">
                    <MemberInfo
                      :playerno="item.player_pk"
                      :member_img="item.member_img"
                      :badge_img="item.badge_img"
                      :class_bind="'avatar-md'"
                    />
                    <div>
                      <MemberInfo
                        :nickname="item.nickname"
                        :playerno="item.player_pk"
                        :avatar_img="false"
                        class="fw-500"
                      />
                      <p class="fs-12 fw-normal shop-name">
                        {{ item.shopname || "-" }}
                      </p>
                    </div>
                  </td>
                  <td>{{ item.rank_field | toFixed }}</td>
                </tr>
              </template>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="3" class="text-center">
                  {{ $t("text.common.no_data") }}
                </td>
              </tr>
            </tbody>
          </table>
          <b-button
            class="btn-text d-flex ms-auto mt-2 fs-13"
            @click="
              $router
                .push({
                  name: 'Stats',
                  params: { searchdate: searchData.searchdate },
                  query: {
                    rank_field: 'avg_score',
                  },
                })
                .catch(() => {})
            "
          >
            {{ $t("button.common.view_all") }}
          </b-button>
        </div>

        <div class="col">
          <h6 class="subtitle">{{ $t("title.common.gir") }}</h6>
          <table class="table table-xs">
            <thead>
              <tr>
                <th class="w-1">
                  {{ $t("text.common.ranking_abbr") }}
                </th>
                <th>
                  {{ $t("title.common.player") }}
                </th>
                <th class="w-1">%</th>
              </tr>
            </thead>
            <tbody v-if="summary.top_greens_list.length > 0">
              <template v-for="(item, index) in summary.top_greens_list">
                <tr :key="index">
                  <td>
                    <strong class="text-black">{{ item.ranking }}</strong>
                  </td>
                  <td class="d-flex align-items-center text-start">
                    <MemberInfo
                      :playerno="item.player_pk"
                      :member_img="item.member_img"
                      :badge_img="item.badge_img"
                      :class_bind="'avatar-md'"
                    />
                    <div>
                      <MemberInfo
                        :nickname="item.nickname"
                        :playerno="item.player_pk"
                        :avatar_img="false"
                        class="fw-500"
                      />
                      <p class="fs-12 fw-normal shop-name">
                        {{ item.shopname || "-" }}
                      </p>
                    </div>
                  </td>
                  <td>{{ item.rank_field | toFixed | suffix("%") }}</td>
                </tr>
              </template>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="3" class="text-center">
                  {{ $t("text.common.no_data") }}
                </td>
              </tr>
            </tbody>
          </table>
          <b-button
            class="btn-text d-flex ms-auto mt-2 fs-13"
            @click="
              $router
                .push({
                  name: 'Stats',
                  params: { searchdate: searchData.searchdate },
                  query: {
                    rank_field: 'greens',
                  },
                })
                .catch(() => {})
            "
          >
            {{ $t("button.common.view_all") }}
          </b-button>
        </div>

        <div class="col">
          <h6 class="subtitle">{{ $t("title.common.avg_distance") }}</h6>
          <table class="table table-xs">
            <thead>
              <tr>
                <th class="w-1">
                  {{ $t("text.common.ranking_abbr") }}
                </th>
                <th>
                  {{ $t("title.common.player") }}
                </th>
                <th class="w-1">
                  {{ $t("title.common.distance") }}
                </th>
              </tr>
            </thead>
            <tbody v-if="summary.top_avg_drive_list.length > 0">
              <template v-for="(item, index) in summary.top_avg_drive_list">
                <tr :key="index">
                  <td>
                    <strong class="text-black">{{ item.ranking }}</strong
                    ><br />
                  </td>
                  <td class="d-flex align-items-center text-start">
                    <MemberInfo
                      :playerno="item.player_pk"
                      :member_img="item.member_img"
                      :badge_img="item.badge_img"
                      :class_bind="'avatar-md'"
                    />
                    <div>
                      <MemberInfo
                        :nickname="item.nickname"
                        :playerno="item.player_pk"
                        :avatar_img="false"
                        class="fw-500"
                      />
                      <p class="fs-12 fw-normal shop-name">
                        {{ item.shopname || "-" }}
                      </p>
                    </div>
                  </td>
                  <td>
                    {{ item.rank_field | toFixed | suffix("m") }}
                  </td>
                </tr>
              </template>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="3" class="text-center">
                  {{ $t("text.common.no_data") }}
                </td>
              </tr>
            </tbody>
          </table>
          <b-button
            class="btn-text d-flex ms-auto mt-2 fs-13"
            @click="
              $router
                .push({
                  name: 'Stats',
                  params: { searchdate: searchData.searchdate },
                  query: {
                    rank_field: 'avg_drive',
                  },
                })
                .catch(() => {})
            "
          >
            {{ $t("button.common.view_all") }}
          </b-button>
        </div>

        <div class="col">
          <h6 class="subtitle">{{ $t("title.common.avg_putts_distance") }}</h6>
          <table class="table table-xs">
            <thead>
              <tr>
                <th class="w-1">
                  {{ $t("text.common.ranking_abbr") }}
                </th>
                <th>
                  {{ $t("title.common.player") }}
                </th>
                <th class="w-1">
                  {{ $t("title.common.distance") }}
                </th>
              </tr>
            </thead>
            <tbody v-if="summary.top_avg_putt_list.length > 0">
              <template v-for="(item, index) in summary.top_avg_putt_list">
                <tr :key="index">
                  <td>
                    <strong class="text-black">{{ item.ranking }}</strong
                    ><br />
                  </td>
                  <td class="d-flex align-items-center text-start">
                    <MemberInfo
                      :playerno="item.player_pk"
                      :member_img="item.member_img"
                      :badge_img="item.badge_img"
                      :class_bind="'avatar-md'"
                    />
                    <div>
                      <MemberInfo
                        :nickname="item.nickname"
                        :playerno="item.player_pk"
                        :avatar_img="false"
                        class="fw-500"
                      />
                      <p class="fs-12 fw-normal shop-name">
                        {{ item.shopname || "-" }}
                      </p>
                    </div>
                  </td>
                  <td>
                    {{ item.rank_field | toFixed | suffix("m") }}
                  </td>
                </tr>
              </template>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="3" class="text-center">
                  {{ $t("text.common.no_data") }}
                </td>
              </tr>
            </tbody>
          </table>
          <b-button
            class="btn-text d-flex ms-auto mt-2 fs-13"
            @click="
              $router
                .push({
                  name: 'Stats',
                  params: { searchdate: searchData.searchdate },
                  query: {
                    rank_field: 'avg_putt',
                  },
                })
                .catch(() => {})
            "
          >
            {{ $t("button.common.view_all") }}
          </b-button>
        </div>

        <div class="col">
          <h6 class="subtitle">{{ $t("title.common.par_save_rate") }}</h6>
          <table class="table table-xs">
            <thead>
              <tr>
                <th class="w-1">
                  {{ $t("text.common.ranking_abbr") }}
                </th>
                <th>
                  {{ $t("title.common.player") }}
                </th>
                <th class="w-1">%</th>
              </tr>
            </thead>
            <tbody v-if="summary.top_parsave_list.length > 0">
              <template v-for="(item, index) in summary.top_parsave_list">
                <tr :key="index">
                  <td>
                    <strong class="text-black">{{ item.ranking }}</strong>
                  </td>
                  <td class="d-flex align-items-center text-start">
                    <MemberInfo
                      :playerno="item.player_pk"
                      :member_img="item.member_img"
                      :badge_img="item.badge_img"
                      :class_bind="'avatar-md'"
                    />
                    <div>
                      <MemberInfo
                        :nickname="item.nickname"
                        :playerno="item.player_pk"
                        :avatar_img="false"
                        class="fw-500"
                      />
                      <p class="fs-12 fw-normal shop-name">
                        {{ item.shopname || "-" }}
                      </p>
                    </div>
                  </td>
                  <td>
                    {{ item.rank_field | toFixed | suffix("%") }}
                  </td>
                </tr>
              </template>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="3" class="text-center">
                  {{ $t("text.common.no_data") }}
                </td>
              </tr>
            </tbody>
          </table>
          <b-button
            class="btn-text d-flex ms-auto mt-2 fs-13"
            @click="
              $router
                .push({
                  name: 'Stats',
                  params: { searchdate: searchData.searchdate },
                  query: {
                    rank_field: 'parsave',
                  },
                })
                .catch(() => {})
            "
          >
            {{ $t("button.common.view_all") }}
          </b-button>
        </div>

        <div class="col">
          <h6 class="subtitle">{{ $t("title.common.fareway_hit") }}</h6>
          <table class="table table-xs">
            <thead>
              <tr>
                <th class="w-1">
                  {{ $t("text.common.ranking_abbr") }}
                </th>
                <th>
                  {{ $t("title.common.player") }}
                </th>
                <th class="w-1">%</th>
              </tr>
            </thead>
            <tbody v-if="summary.top_fairway_list.length > 0">
              <template v-for="(item, index) in summary.top_fairway_list">
                <tr :key="index">
                  <td>
                    <strong class="text-black">{{ item.ranking }}</strong>
                  </td>
                  <td class="d-flex align-items-center text-start">
                    <MemberInfo
                      :playerno="item.player_pk"
                      :member_img="item.member_img"
                      :badge_img="item.badge_img"
                      :class_bind="'avatar-md'"
                    />
                    <div>
                      <MemberInfo
                        :nickname="item.nickname"
                        :playerno="item.player_pk"
                        :avatar_img="false"
                        class="fw-500"
                      />
                      <p class="fs-12 fw-normal shop-name">
                        {{ item.shopname || "-" }}
                      </p>
                    </div>
                  </td>
                  <td>
                    {{ item.rank_field | toFixed | suffix("%") }}
                  </td>
                </tr>
              </template>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="3" class="text-center">
                  {{ $t("text.common.no_data") }}
                </td>
              </tr>
            </tbody>
          </table>
          <b-button
            class="btn-text d-flex ms-auto mt-2 fs-13"
            @click="
              $router
                .push({
                  name: 'Stats',
                  params: { searchdate: searchData.searchdate },
                  query: {
                    rank_field: 'fairway',
                  },
                })
                .catch(() => {})
            "
          >
            {{ $t("button.common.view_all") }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/halloffame";
import { myMixin } from "@/mixin/myMixin";
import "@/filter/common";

export default {
  name: "StatsSummary",
  components: {
    location,
  },
  data() {
    return {
      searchData: {
        searchdate:
          this.$route.query.searchdate ||
          (this.$store.state.searchData[this.$route.matched[0].name] &&
            this.$store.state.searchData[this.$route.matched[0].name]
              .searchdate) ||
          this.$dayjs(new Date().setYear(new Date().getFullYear())).format(
            "YYYY"
          ),
      },

      summary: {
        top_avg_score_list: [],
        top_greens_list: [],
        top_avg_drive_list: [],
        top_avg_putt_list: [],
        top_parsave_list: [],
        top_fairway_list: [],
      },
      view_hide: null,
    };
  },
  methods: {
    prev() {
      if (this.searchData.searchdate < 2025) return;
      this.searchData.searchdate--;
    },
    next() {
      if (
        this.searchData.searchdate ==
        this.$dayjs(new Date().setYear(new Date().getFullYear())).format("YYYY")
      )
        return;
      this.searchData.searchdate++;
    },
    get_stats_summary() {
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });
      ax.get_stats_summary(this.searchData, (flag, data) => {
        this.summary = data.summary;

        if (!flag) alert(data.message);
      });
    },
    onSubmit(event) {
      event.preventDefault();
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
  },
  created() {
    this.get_stats_summary();
  },
  watch: {
    "searchData.searchdate": function () {
      this.get_stats_summary();
    },
  },
  mixins: [myMixin],
};
</script>
